.search {
  position: relative;
  color: #aaa;
  font-size: 16px;
  
}

.inputStyle {
  height: 30px;
  border-radius: 4px;
  padding: 10px;
  border: 1px solid #c4c4c4;
}

.search {
  display: inline-block;
}

.search input {
  width: 250px;
  height: 32px;

  background: #fcfcfc;
  border: 1px solid #aaa;
  border-radius: 5px;
  box-shadow: 0 0 3px #ccc, 0 10px 15px #ebebeb inset;
}

.search input {
  text-indent: 32px;
}

.search .faSearch {
  position: absolute;
  top: 10px;
  left: 10px;
}

.search .faSearch {
  left: auto;
  right: 10px;
}