.identityBox {
  background: linear-gradient(180deg, #ffffff 0%, #f3f3f3 100%);
  // border: 1px solid #909090;
  height: auto;
  width: 70%;
}
.uploadContainer {
  background: linear-gradient(180deg, #ffffff 0%, #f3f3f3 100%);
  border: 1px solid #909090;
  width: 100%;
  height: 200px;
}
.fileuploadContainer {
  background: linear-gradient(180deg, #ffffff 0%, #f3f3f3 100%);
  border: 1px solid #909090;
  width: 50%;
  height: 200px;
}
