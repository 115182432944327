@import 'styles/breakpoint-mixins.scss';
@import 'styles/variables.scss';

.contentContainer {
  min-height: 90vh;
}

.paperBg {
  padding: 20px;
  @include media-breakpoint-up('sm') {
    width: 70%;
    margin: 40px;
  }
}
.paperBg:has(.home, .payCalc) {
  width: 100%;
  margin: none;
  padding: none;
}
.paperBg:has(.lookup, .equipAdjustment, .payCalcPaper, .dummyHome, .infoRequestPaper) {
  @include media-breakpoint-up('sm') {
    width: 100%;
    margin: 0;
  }
}
.paperBg:has(.camContainer),
.infoRequestPaper:has(.camContainer) {
  background-color: transparent;
}

.multilineInputComment {
  &:focus {
    border: 1px solid orange;
  }
  border-radius: 4px;
  padding: 10px;

  font-family: Arial, Helvetica, sans-serif;
}
.lookup {
  display: flex;
  justify-content: center;
  padding: 20px;
  min-height: 80vh;
  gap: 40px;
  flex-wrap: wrap;
  background: #ffffff;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 6%;
}

.childBorderLine > div,
.borderTable {
  border-bottom: 1px solid #f2f2f2;
}

.tableOutline {
  border: 1px solid #dadada;
}

.equipAdjustment {
  display: flex;
  justify-content: space-between;
  gap: 20px;

  margin-top: 20px;

  @include media-breakpoint-down('sm') {
    flex-direction: column;
  }
}

.removeBorder {
  border: none;
}

.footer {
  min-height: 48px;
  height: 48px;
}

.capitalize::first-letter {
  text-transform: capitalize;
}

.heading {
  border-bottom: 1px solid #909090;
  color: #e37617;

  padding: 10px 0;
  margin: 16px 0;
  width: 100%;
  text-align: left;
}

.customContainer {
  padding: 4px 8px;
  align-items: center;
  @include media-breakpoint-down('sm') {
    padding: 8px 4px;
  }
}
.numberFormat {
  width: 100%;
}

.vinInput {
  min-width: 70%;
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
  }
}
.listItemBtnClose {
  min-height: 48;
  justify-content: initial;
  padding: 0 20px;
}
.listItemBtnOpen {
  min-height: 48;
  text-align: center;
  display: block;
  padding: 0;
}

.hideTotalCheckBox {
  div[role='row']:last-of-type {
    background-color: $secondary;
    color: white;
    div[data-field='__check__'] {
      visibility: hidden;
    }
  }
}
.cellContent {
  white-space: normal;
}
.totalAlign {
  .totalHeading {
    min-width: 250px;
    max-width: 250px;
    margin: 10px;
  }
  p {
    min-width: 100px;
    max-width: 100px;
  }
}
.customNumberFormat {
  margin: 10px 0;
}
.creditLifeContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  .box1 {
    width: 50%;
    margin-left: 16px;
  }
  .box2 {
    display: flex;
    width: 50%;
  }
}
.modalIconBtn {
  float: right;
  padding: 0;
}
.resultBtn {
  padding: 40px 0;
  text-align: center;
}
.customSelectError {
  // width: 47%;
  // float: right;
  color: #d32f2f;
}
.customUpload {
  border: 2px solid rgb(212, 212, 212);
  padding: 10px;
  width: 100%;
  border-radius: 5px;
}
.resultsBox {
  padding: 8px 0;
  border: 1px solid grey;
}
.infoRequestPaper {
  padding: 10px 20px 20px 20px;
  min-height: 80vh;
  gap: 8px;
  flex-wrap: wrap;
  background: #ffffff;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 6%;
}
.payCalcPaper {
  display: flex;
  justify-content: center;
  padding: 10px 20px 20px 20px;
  min-height: 80vh;
  gap: 8px;
  flex-wrap: wrap;
  background: #ffffff;
  box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-bottom: 6%;
}

.radioLabel {
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}
.customToggleText {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000000;
  margin: 0;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.tableHeadingList {
  border: none;
  width: 60%;
  font-size: 14px !important;
  padding: 8px;
}

.currencyValue {
  font-size: 14px !important;
  font-weight: 700 !important;
  color: #661c69;
  border: none;
  text-transform: capitalize;
}
.tableContainer {
  border: 1px solid #dadada;
  background-color: #fff;
}
.TableHeading {
  font-weight: 700;
  background: #f2f2f2;
  border: 1px solid #dadada;
  font-size: 14px !important;
  border: none;

  padding: 8px;
}

.reviewAndSubmit {
  .collapsableDiv {
    padding: 0px 60px;
  }
  .currencyValue {
    font-size: 14px !important;
    font-weight: 700 !important;
    color: #661c69;
    border: none;
    text-transform: none;
  }
}

.logoutStyling {
  font-size: '16px';
  min-width: '90px';
  height: '40px';
  font-weight: 700;
}
