.TableHeading {
  font-size: 14px;
  font-weight: 700;
}
.TableCell {
  font-size: 14px;
  padding: 10px;
}
.resultContainer {
  background-color: #e37617;
  padding: 0 20px 20px 20px;
  border-radius: 5px;
}
.resultContent {
  display: flex;
  justify-content: space-between;
  color: #fff;
  align-items: center;
}
.printContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.printContent {
  text-decoration: underline;
  text-decoration-style: solid;
}
.tableContainer {
  border: 1px solid #dadada;
  background-color: #fff;
}
.appBtn {
  display: flex;
  justify-content: flex-end;
  margin: 15px 0;
}
.results {
  text-align: start;
  font-size: 14px;
  width: 100%;
  font-weight: 700;
  border: none;
}
.tableContentCell {
  padding: 10px;
}
