@mixin media-breakpoint-down($device) {
  @if $device == 'xs' {
    @media (max-width: 599px) {
      @content;
    }
  }
  @if $device == 'sm' {
    @media (max-width: 899px) {
      @content;
    }
  }
  @if $device == 'md' {
    @media (max-width: 1199px) {
      @content;
    }
  }
  @if $device == 'lg' {
    @media (max-width: 1535px) {
      @content;
    }
  }
}

@mixin media-breakpoint-up($device) {
    @if $device == 'xs' {
      @media (min-width: 599px) {
        @content;
      }
    }
    @if $device == 'sm' {
      @media (min-width: 899px) {
        @content;
      }
    }
    @if $device == 'md' {
      @media (min-width: 1199px) {
        @content;
      }
    }
    @if $device == 'lg' {
      @media (min-width: 1535px) {
        @content;
      }
    }
  }

